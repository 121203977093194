import { NodeIdAnswersResolver, NodeIdToAnswerPathMap } from '@breathelife/questionnaire-engine';
import { Answers, Localizable, PaymentSelector, ProductsEntity, QuoteInfo } from '@breathelife/types';

import { ProductsEntityGenerator } from './entities/Products';
import { InsuranceProduct } from './types/InsuranceProduct';

export type EntitySelectors = {
  payment: PaymentSelector | null;
};

export class InsuranceEntities {
  private readonly answersResolver: NodeIdAnswersResolver;
  private readonly entitySelectors: EntitySelectors;

  constructor(
    nodeIdToAnswerPathMap: NodeIdToAnswerPathMap,
    entitySelector: EntitySelectors = {
      payment: null,
    },
  ) {
    this.answersResolver = new NodeIdAnswersResolver(nodeIdToAnswerPathMap);
    this.entitySelectors = entitySelector;
  }

  getProducts<T extends string | Localizable = Localizable>(
    answers: Answers,
    productsInfo: InsuranceProduct<T>[],
    quotes?: QuoteInfo,
    hideRidersPremium?: boolean,
  ): ProductsEntity<T> {
    const productsEntityGenerator = new ProductsEntityGenerator(this.answersResolver);
    return productsEntityGenerator.get(answers, productsInfo, this.entitySelectors.payment, quotes, hideRidersPremium);
  }
}

import { GenderAtBirth, YesNoValue } from '@breathelife/types';

export function isValidDateString(date: unknown): date is string {
  if (typeof date === 'string') {
    const timestamp = Date.parse(date);
    return !isNaN(timestamp);
  } else {
    return false;
  }
}

export function isGenderAtBirth(gender: unknown): gender is GenderAtBirth {
  if (typeof gender === 'string') {
    return Object.values<string>(GenderAtBirth).includes(gender);
  } else {
    return false;
  }
}

export function isYesNoValue(isTobaccoUser: unknown): isTobaccoUser is YesNoValue {
  if (typeof isTobaccoUser === 'string') {
    return Object.values<string>(YesNoValue).includes(isTobaccoUser);
  } else {
    return false;
  }
}
